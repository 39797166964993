import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

//import image1 from "assets/img/globe-send.jpg";
import image2 from "assets/img/bg2.jpg";
import image3 from "assets/img/bg3.jpg";
import image4 from "assets/img/courosal.jpeg";
//import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

//const useStyles = makeStyles(styles);

export default function SectionCarousel() {
  //const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img src={image4} alt="First slide" className="slick-image" />
                  <div
                    style={{
                      backgroundColor: "#C0CED3",

                      borderColor: "#FFFFFF",
                    }}
                    className="slick-caption"
                  >
                    <h3>BANK TRANSFERS</h3>
                    <h4>
                      <LocationOn className="slick-icons" />
                      Transfer cash Directly to most bank accounts in Kenya and
                      East africa
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image2} alt="First slide" className="slick-image" />
                  <div
                    style={{
                      backgroundColor: "#C0CED3",

                      borderColor: "#FFFFFF",
                    }}
                    className="slick-caption"
                  >
                    <h3>BANK TRANSFERS</h3>
                    <h4>
                      <LocationOn className="slick-icons" />
                      Transfer cash Directly to most bank accounts in Kenya and
                      East africa
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image2}
                    alt="Second slide"
                    className="slick-image"
                  />
                  <div
                    style={{
                      backgroundColor: "#C0CED3",

                      borderColor: "#FFFFFF",
                    }}
                    className="slick-caption"
                  >
                    <h3>MOBILE MONEY TRANSFERS</h3>
                    <h4>
                      <LocationOn className="slick-icons" />
                      Transfer cash Directly to individual mobile numbers or
                      Paybills
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image3} alt="Third slide" className="slick-image" />
                  <div
                    style={{
                      backgroundColor: "#C0CED3",

                      borderColor: "#FFFFFF",
                    }}
                    className="slick-caption"
                  >
                    <h3>WALLETS</h3>
                    <h4>
                      <LocationOn className="slick-icons" />
                      Send and receive money directly from your card to qsend
                      wallet of your recipient.
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image3} alt="Third slide" className="slick-image" />
                  <div
                    style={{
                      backgroundColor: "#C0CED3",

                      borderColor: "#FFFFFF",
                    }}
                    className="slick-caption"
                  >
                    <h3>CASH PICK UPS</h3>
                    <h4>
                      <LocationOn className="slick-icons" />
                      With numerous pick up points in Nairobi and major towns in
                      Kenya, you can send money to individuals without a qsend
                      account
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
