import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
import SectionCarousel from "./SectionCarousel";
import { Button, ListItem, TextField, Card } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import CurrencyFlag from "react-currency-flags";
const useStyles = makeStyles(styles);

export default function SectionPills() {
  const classes = useStyles();
  const [currency, setCurrency] = useState([]);
  const options = [
    {
      id: 1,
      pair: "USD",
    },
    {
      id: 1,
      pair: "EUR",
    },
  ];

  const getValues = () => {
    axios
      .get(
        "http://api.exchangeratesapi.io/v1/latest?access_key=c3298a127d4207c709c8790ce79bd858"
      )
      .then((response) => {
        console.log(Object.keys(response.data.rates));
        setCurrency(response.data.rates);
      });
  };

  const setValues = (e, values) => {
    console.log(values);
    Object.keys(currency).map((value) => {
      console.log(value);
    });
  };

  const Flag = () => <CurrencyFlag currency="USD" size="sm" />;

  // const CurrencyFlagComponent2 = () => (
  //   <CurrencyFlag currency="EUR" width={38} />
  // );
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <GridContainer>
            <GridItem xs={12} sm={12} md={8} lg={6}>
              <NavPills
                color="primary"
                tabs={[
                  {
                    tabButton: "Deliverly Mode",
                    tabIcon: Dashboard,
                    tabContent: (
                      <span>
                        <SectionCarousel />
                      </span>
                    ),
                  },
                  {
                    tabButton: "How it Works",
                    tabIcon: Schedule,
                    tabContent: (
                      <span>
                        <Card className="mt--3">
                          <ListItem>
                            1.Sign up with Qsend through the app or website.
                          </ListItem>{" "}
                          <ListItem>
                            2.Load your Qsend wallet (Mpesa, Debit card, Credit
                            card, prepaid Card)
                          </ListItem>
                          <ListItem>
                            {" "}
                            3.Select destination of funds (Country drop down
                            list)
                          </ListItem>
                          <ListItem>
                            {" "}
                            4.Select Currency Select amount you want to send Add
                            your recipient details (Based on delivery mode)
                          </ListItem>{" "}
                          <ListItem>
                            5.Then <Button variant="contained">send</Button>
                            {Flag}
                          </ListItem>
                        </Card>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Your money Security",
                    tabIcon: List,
                    tabContent: (
                      <span>
                        <Card>
                          <h4>Dedicated fraud team</h4>
                          <p>
                            We work round the clock to keep your account and
                            money protected from even the most sophisticated
                            fraud.
                          </p>
                        </Card>
                        <br />
                        <Card>
                          <h4>Real-time notifications</h4>
                          <p className="m-0 mt-1 text-primary font-medium">
                            Every time you spend with your card, you get instant
                            notifications on your device.
                          </p>
                        </Card>
                        <br />
                        <Card>
                          <h4>Extra secure transactions</h4>
                          <p>
                            We use two factor authentication to protect your
                            account and transactions. That means you - and only
                            you - can get to your money.
                          </p>
                        </Card>
                        <br />
                        <Card>
                          <h4>Feel safe 24/7</h4>
                          <p>
                            Our security operations team is monitoring
                            constantly for any anomalies and keeping up with the
                            latest attack vectors to keep your data and money
                            safe. We run regular vulnerability scans and use
                            independent auditors for internal and external
                            penetration tests and other security scans.
                          </p>
                        </Card>
                      </span>
                    ),
                  },
                  {
                    tabButton: "About Qsend",
                    tabIcon: List,
                    tabContent: (
                      <span>
                        <p>
                          Collaboratively administrate empowered markets via
                          plug-and-play networks. Dynamically procrastinate B2C
                          users after installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <NavPills
                color="blue"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 8, md: 8 },
                }}
                tabs={[
                  {
                    tabButton: "Currency exchages ",
                    tabIcon: Dashboard,
                    tabContent: (
                      <span>
                        <p>
                          <Autocomplete
                            //className="mb-4 w-300"
                            options={options}
                            getOptionLabel={(option) => option.pair}
                            onChange={getValues}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Base Currency"
                                name="Currency"
                                variant="outlined"
                                style={{
                                  backgroundColor: "white",
                                  background: "white",
                                  borderColor: "#FFFFFF",
                                }}
                                fullWidth
                              />
                            )}
                          />
                        </p>
                        <br />
                        <p>
                          <Autocomplete
                            //className="mb-4 w-300"
                            options={Object.keys(currency)}
                            getOptionLabel={(option) => option}
                            onChange={setValues}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Base Currency"
                                name="Currency"
                                variant="outlined"
                                style={{
                                  backgroundColor: "white",
                                  background: "white",
                                  borderColor: "#FFFFFF",
                                }}
                                fullWidth
                              />
                            )}
                          />
                        </p>
                        <p
                          style={{
                            backgroundColor: "white",
                            color: "#FFFFFF",
                          }}
                        >
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Currency History",
                    tabIcon: Schedule,
                    tabContent: (
                      <span>
                        <p>
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                        </p>
                        <br />
                        <p>
                          Dramatically maintain clicks-and-mortar solutions
                          without functional solutions. Dramatically visualize
                          customer directed convergence without revolutionary
                          ROI. Collaboratively administrate empowered markets
                          via plug-and-play networks. Dynamically procrastinate
                          B2C users after installed base benefits.
                        </p>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
