import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import logo from "./logo.png";
// @material-ui/icons
// core components
import { Grid, TextField } from "@material-ui/core";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
//import SectionBasics from "./Sections/SectionBasics.js";
//mport SectionNavbars from "./Sections/SectionNavbars.js";
//import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
import { Autocomplete } from "@material-ui/lab";
//import SectionNotifications from "./Sections/SectionNotifications.js";
//import SectionTypography from "./Sections/SectionTypography.js";
//import SectionJavascript from "./Sections/SectionJavascript.js";
//import SectionCarousel from "./Sections/SectionCarousel.js";
//import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
//import SectionLogin from "./Sections/SectionLogin.js";
//import SectionExamples from "./Sections/SectionExamples.js";
//import SectionDownload from "./Sections/SectionDownload.js";
import ReactCountryFlag from "react-country-flag";
import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();

  const [currencylabel, setCurrencyLabel] = useState("");
  const [currencylabel1, setCurrencyLabel2] = useState("");
  const [change, setChange] = useState(true);
  const [flag, setFlag] = useState(false);
  const [convert, setConvert] = useState("");
  const [inside, setInside] = useState("");
  const { ...rest } = props;
  const options = [
    {
      id: 1,
      pair: "USD - KES",
    },
  ];

  const getValues = (e, newValue) => {
    //console.log(newValue)

    //setCurrencyPair(value.label);
    const split = newValue.pair.toString();
    const splited = split.substring(0, 3);
    const splited2 = split.substring(5, 9);

    setCurrencyLabel(splited);
    setCurrencyLabel2(splited2);
    setChange(false);
    setFlag(true);
    setInside(convert);
  };

  const handleChange = (values) => {
    setConvert(values.target.value);
  };
  return (
    <div>
      <Header
        rightLinks={<HeaderLinks />}
        brand={
          <img
            src={logo}
            alt="fireSpot"
            style={{
              fontSize: "1em",
              lineHeight: "1em",
              maxHeight: "3em",
            }}
          />
        }
        fixed
        //color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/bgi.jpeg").default}>
        <div className={classes.container}>
          <Grid container spacing={1}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              {/* <div className={classes.brand}>
                <h2 className={classes.title}>Qsend Payments.</h2>
                <h3 className={classes.subtitle}>
                  send money across the world in instantly.
                </h3>
                <Button>learn More</Button>
                <h4 className={classes.subtitle}>
                  cheapest way to send money abroad.
                </h4>
              </div> */}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div className={classes.brand}>
                <TextField
                  fullWidth
                  label="Amount u wish to send"
                  name="firstname"
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange}
                  style={{
                    backgroundColor: "white",
                    background: "white",
                    borderColor: "#FFFFFF",
                  }}
                />
                <Grid container spacing={1}>
                  <Grid Grid item xs={6} sm={6}>
                    <div>
                      {change && (
                        <h4>
                          send money across the world in instantly
                          {currencylabel}.
                        </h4>
                      )}

                      {flag && (
                        <div>
                          <span>Send From </span>
                          <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="US"
                            style={{
                              fontSize: "1em",
                              lineHeight: "1em",
                            }}
                            aria-label="United States"
                          />
                          <span> Directly to </span>
                          <ReactCountryFlag
                            className="emojiFlag"
                            countryCode="KE"
                            style={{
                              fontSize: "1em",
                              lineHeight: "1em",
                            }}
                            aria-label="United States"
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid Grid item xs={6} sm={6}>
                    <Autocomplete
                      //className="mb-4 w-300"
                      options={options}
                      getOptionLabel={(option) => option.pair}
                      onChange={getValues}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Currency"
                          name="Currency"
                          variant="outlined"
                          style={{
                            backgroundColor: "white",
                            background: "white",
                            borderColor: "#FFFFFF",
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  label={"Amount to receive in " + currencylabel1}
                  name="firstname"
                  margin="normal"
                  variant="outlined"
                  value={inside * 109.7}
                  style={{
                    backgroundColor: "white",
                    background: "white",
                    color: "#FFFFFF",
                  }}
                />
                <Button>learn More</Button>
                <></>

                <Button href="https://sandbox.habary.co.ke/qsend/">
                  Get Started
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        {/* <SectionBasics /> */}
        {/* <SectionNavbars /> */}
        {/* <SectionTabs /> */}
        <SectionPills />
        {/* <SectionNotifications /> */}
        {/* <SectionTypography /> */}
        {/* <SectionJavascript />*/}
        {/* <SectionCarousel />
        <SectionCompletedExamples /> */}
        {/* <SectionLogin />
        <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem> */}
        {/* <SectionExamples /> */}
        {/* <SectionDownload /> */}
      </div>
      <Footer />
    </div>
  );
}
